@font-face {
    font-family: 'Causten';
    src: local('Causten'), url(./fonts/Causten-Regular.ttf) format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'Causten';
    src: local('Causten'), url(./fonts/Causten-Bold.ttf) format('truetype');
    font-weight: bold;
}

@font-face {
    font-family: 'Causten';
    src: local('Causten'), url(./fonts/Causten-Semi-Bold.ttf) format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'Causten';
    src: local('Causten'), url(./fonts/Causten-Medium.ttf) format('truetype');
    font-weight: 500;
} 